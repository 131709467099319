import {Link} from '@remix-run/react'
import {useMemo} from 'react'
import {repoMetadata} from '~/routes/_marketing.benchmark.$repo/route'

interface BuildTimeChartProps {
  repo: string
  metrics: {
    id: number
    createdAt: string
    depotDuration: number
    dockerDuration: number
    upstreamCommitMessage: string
  }[]
}

export function BuildTimeChart({repo, metrics}: BuildTimeChartProps) {
  const sortedMetrics = useMemo(() => {
    const sorted = metrics.sort((a, b) => a.createdAt.localeCompare(b.createdAt))
    return sorted.length > 10 ? sorted.slice(sorted.length - 10) : sorted
  }, [metrics])

  const avg = useMemo(() => {
    const avg = sortedMetrics.reduce(
      (acc, curr) => {
        acc.depotDuration += curr.depotDuration
        acc.dockerDuration += curr.dockerDuration
        return acc
      },
      {depotDuration: 0, dockerDuration: 0},
    )
    return {
      depotDuration: Math.floor(avg.depotDuration / sortedMetrics.length),
      dockerDuration: Math.floor(avg.dockerDuration / sortedMetrics.length),
    }
  }, [sortedMetrics])

  const timesFaster = useMemo(() => {
    const times = avg.dockerDuration / avg.depotDuration
    if (times > 10) return Math.round(times)
    return Math.round(times * 10) / 10
  }, [avg])

  const metadata = repoMetadata[repo.replace('benchmark-', '')]

  return (
    <Link
      to={`/benchmark/${repo.replace('benchmark-', '')}`}
      className="flex w-full flex-col whitespace-nowrap leading-none hover:border-radix-mauve6"
    >
      <div className="flex flex-1 items-center justify-between gap-4 pb-4">
        <div className="font-display text-xl font-semibold text-radix-mauve12">{metadata?.upstream ?? repo}</div>
        <div className="text-right">
          <div className="font-semibold text-radix-grass11">{timesFaster}x faster</div>
        </div>
      </div>

      <div className="space-y-2">
        <div className="flex items-center justify-between gap-2 rounded border border-radix-mauve5 bg-radix-mauve1 p-2">
          <div className="flex-1">
            <div
              className="flex h-8 shrink-0 items-center rounded-sm border border-radix-grass6 bg-radix-grass4 px-2"
              style={{width: `${(avg.depotDuration / avg.dockerDuration) * 100}%`}}
            >
              <div className="rounded bg-radix-grass4/50 pr-1 text-sm text-radix-grass11">With Depot</div>
            </div>
          </div>
          <div className="w-16 shrink-0 text-right text-xs text-radix-mauve11">{formatDuration(avg.depotDuration)}</div>
        </div>

        <div className="flex items-center justify-between gap-2 rounded border border-radix-mauve5 bg-radix-mauve1 p-2">
          <div className="flex-1">
            <div className="flex h-8 shrink-0 items-center rounded-sm border border-radix-mauve6 bg-radix-mauve4 px-2">
              <div className="text-sm text-radix-mauve11">Without Depot</div>
            </div>
          </div>
          <div className="w-16 shrink-0 text-right text-xs text-radix-mauve11">
            {formatDuration(avg.dockerDuration)}
          </div>
        </div>
      </div>
    </Link>
  )
}

function formatDuration(seconds: number) {
  if (seconds < 60) return `${seconds}s`
  const minutes = Math.floor(seconds / 60)
  const remainder = seconds % 60
  return `${minutes}m ${remainder}s`
}
