import {TweetEmbed} from './TweetEmbed'

export function TweetGrid() {
  return (
    <div className="mx-4 columns-xs gap-4 space-y-4">
      <TweetEmbed
        id="1783605294628581817"
        name="jack"
        username="jackw_xyz"
        date="April 25, 2024"
        time="10:13 PM"
        image="/images/twitter/jackw_xyz.webp"
      >
        <div>you wanna see some real speed?</div>
        <div>Building and releasing a complete nextjs app in 1m3s</div>
        <div>
          (with help from <span className="text-radix-blue11">@depotdev</span> - insane 16s build and push thanks to
          caching)
        </div>
        <div>
          <img
            src="/images/twitter/1783605294628581817.webp"
            className="rounded-sm border border-radix-mauve7"
            decoding="async"
            loading="lazy"
            width="1600"
            height="759"
            alt="Screenshot showing a GitHub Actions workflow where the build and push step takes 16 seconds"
          />
        </div>
      </TweetEmbed>

      <TweetEmbed
        id="1775769850222448836"
        name="nacho"
        username="ignacioaal"
        date="Apr 4, 2024"
        time="7:18 AM"
        image="/images/twitter/ignacioaal.webp"
      >
        <div>
          ok <span className="text-radix-blue11">@depotdev</span> is awesome! got my build & push from 30 mins to 4 and
          it will be about 12 seconds when cached *chef kiss*
        </div>
      </TweetEmbed>

      <TweetEmbed
        id="1767964282405188060"
        name="Dan Loewenherz"
        username="dwlz"
        date="March 13, 2024"
        time="5:21 PM"
        image="/images/twitter/dwlz.webp"
      >
        <div>
          Just want to give a public shoutout to <span className="text-radix-blue11">@depotdev</span>. Since migrating
          from the default GitHub actions runner, our Docker image build times have literally been cut in half.
        </div>
        <div>Super impressive. So worth it. We are very happy customers. 😀</div>
      </TweetEmbed>

      <TweetEmbed
        id="1747532424039317606"
        name="Shubham"
        username="ShubhamInTech"
        date="Jan 17, 2024"
        time="8:12 AM"
        image="/images/twitter/ShubhamInTech.webp"
      >
        <div>
          qemu emulations & buildkit made our arm builds really slow! tried out{' '}
          <span className="text-radix-blue11">@depotdev</span> and saw instant 10x faster builds at{' '}
          <span className="text-radix-blue11">@formbricks</span> 🚀
        </div>
      </TweetEmbed>

      <TweetEmbed
        id="1752785555841036643"
        name="Mike Turck"
        username="turckalicious"
        date="Jan 31, 2024"
        time="8:06 PM"
        image="/images/twitter/turckalicious.webp"
      >
        <div>
          we've found <span className="text-radix-blue11">@depotdev</span> to be incredible for reducing our own build
          times by &gt;10x, from 8 min to 20s in one case
        </div>
      </TweetEmbed>

      <TweetEmbed
        id="1787142744642654678"
        name="Aaron"
        username="aaronbatilo"
        date="May 5, 2024"
        time="4:30 PM"
        image="/images/twitter/aaronbatilo.webp"
      >
        <div>
          I build 3 react apps, and 7 Golang binaries + golang tests on every single commit -- all in about 30 seconds
          total thanks to some very precise docker context management, docker run cache mounts, and the incredible
          caches provided by <span className="text-radix-blue11">@depotdev</span>. All in a single `depot bake` command
        </div>
      </TweetEmbed>

      <TweetEmbed
        id="1620090744408244224"
        name="Matthieu Napoli"
        username="matthieunapoli"
        date="Jan 30, 2023"
        time="4:05 PM"
        image="/images/twitter/matthieunapoli.webp"
      >
        <div>
          I am blown away by <span className="text-radix-blue11">@depotdev</span>, it's making the bref runtimes
          pipeline finally usable
        </div>
        <div>
          "docker build" becomes "depot build" in GitHub Actions and:
          <br />- fast cross-platform builds (e.g. ARM) <br />- everything is cached aggressively
        </div>
        <div>10 to 20 times faster builds!</div>
        <div>
          <img
            src="/images/twitter/1620090744408244224.webp"
            className="rounded-sm border border-radix-mauve7"
            decoding="async"
            loading="lazy"
            width="1600"
            height="759"
            alt="Screenshot summarizing build times before and after switching to Depot, showing a 10 to 20 times speedup"
          />
        </div>
      </TweetEmbed>

      <TweetEmbed
        id="1557765954125418498"
        name="Vlad Ionescu (he/him)"
        username="iamvlaaaaaaad"
        date="Aug 11, 2022"
        time="5:28 PM"
        image="/images/twitter/iamvlaaaaaaad.webp"
      >
        <div>
          "How do these competitors of ours move so fast?! They fix bugs in hours instead of days, they develop new
          features in days instead of weeks, and their sales cycle is weeks instead of months!?!"
        </div>
        <div>
          Pro-tip: build a developer experience team that tries out new tools like{' '}
          <span className="text-radix-blue11">@depotdev</span> 😉
        </div>
      </TweetEmbed>

      <TweetEmbed
        id="1722677723649200466"
        name="Nick"
        username="NickZelei"
        date="Nov 9, 2023"
        time="7:09 PM"
        image="/images/twitter/NickZelei.webp"
      >
        <div>
          Just switched all of my multi-platform container builds over to using{' '}
          <span className="text-radix-blue11">depot.dev</span>. No cache github aciton build was 30m+. Cached was ~8m.
          Initial depot build (no cache): 3m. Amazing.
        </div>
      </TweetEmbed>

      <TweetEmbed
        id="1556825638182330437"
        name="Aidan W Steele"
        username="__steele"
        date="Aug 9, 2022"
        time="3:12 PM"
        image="/images/twitter/__steele.webp"
      >
        <div>Building a container on my laptop: 19m40s, slow, makes me want to cry.</div>
        <div>
          Building it on <span className="text-radix-blue11">@depotdev</span>: 4m42s, speedy, makes me smile. But
          doesn't heat up my room enough, so I have to wear a jumper.
        </div>
      </TweetEmbed>

      <TweetEmbed
        id="1620769389019820033"
        name="Krishnan Chandra"
        username="krishnanc_"
        date="Feb 1, 2023"
        time="1:01 PM"
        image="/images/twitter/krishnanc_.webp"
      >
        Depot has been fantastic for us! We managed to get our build times down from ~8.5 minutes to ~30 seconds using
        the caching and cross-platform builds out of the box 😀
      </TweetEmbed>

      <TweetEmbed
        id="1579635970336780288"
        name="Luis Lavena"
        username="luislavena"
        date="Oct 11, 2022"
        time="1:52 AM"
        image="/images/twitter/luislavena.webp"
        className="hidden sm:block"
      >
        <div>
          Pushed a new build of my <span className="text-radix-blue11">@CrystalLanguage</span> container image
          (hydrofoil-crystal) with native arm64 support! 🥳 🚀
        </div>

        <div>
          Thanks to <span className="text-radix-blue11">@depotdev</span> for making it easier (and faster!) to build ARM
          container images! 😊
        </div>
      </TweetEmbed>

      <TweetEmbed
        id="1587899798396735488"
        name="Dan Manges"
        username="dan_manges"
        date="Nov 2, 2022"
        time="8:09 PM"
        image="/images/twitter/dan_manges.webp"
      >
        <div>
          We switched to <span className="text-radix-blue11">@depotdev</span> for building our docker images at{' '}
          <span className="text-radix-blue11">@rwx_research</span>. Our build times are twice as fast and integration
          was impressively easy with their support for GitHub's OIDC token. It's easy to spot when we switched in our CI
          analytics.
        </div>

        <div>
          <img
            src="/images/twitter/1587899798396735488.webp"
            className="rounded-sm border border-radix-mauve7"
            decoding="async"
            loading="lazy"
            width="1199"
            height="654"
            alt="Screenshot of a graph showing a sharp drop in build times after switching to Depot"
          />
        </div>
      </TweetEmbed>

      <TweetEmbed
        id="1588342162584133632"
        name="moon"
        username="tothemoonrepo"
        date="Nov 4, 2022"
        time="1:27 AM"
        image="/images/twitter/tothemoonrepo.webp"
      >
        Amazing work. Depot really is a game changer.
      </TweetEmbed>
    </div>
  )
}
