import {cx} from 'class-variance-authority'
import {XLogo} from '~/components/logos'

export interface TweetEmbedProps {
  id: string
  name: string
  username: string
  children: React.ReactNode
  image: string
  date: string
  time: string
  className?: string
}

export function TweetEmbed(props: TweetEmbedProps) {
  return (
    <a
      className={cx(
        props.className,
        'relative block break-inside-avoid rounded border border-radix-mauve6 bg-radix-mauve-black/50 p-4 transition duration-500 hover:border-radix-blue7',
      )}
      href={`https://x.com/${props.username}/status/${props.id}`}
      target="_blank"
      rel="noreferrer"
    >
      <XLogo className="absolute right-4 h-5 w-5 text-radix-mauve12" />
      <div>
        <div className="mb-3 flex items-center gap-3">
          <img
            src={props.image}
            alt={props.name}
            className="block h-12 w-12 rounded-full"
            decoding="async"
            loading="lazy"
            width="48"
            height="48"
          />
          <div>
            <div className="font-display font-bold leading-none">{props.name}</div>
            <div className="text-sm text-radix-mauve11">@{props.username}</div>
          </div>
        </div>
        <div className="space-y-2 text-radix-mauve11">{props.children}</div>
      </div>
    </a>
  )
}
