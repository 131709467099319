export function ProtobufCode() {
  return (
    <div className="relative select-none overflow-x-hidden whitespace-pre bg-gradient-to-r from-radix-mauve10 to-radix-mauve-black bg-clip-text font-mono text-sm text-transparent">
      <div>syntax = "proto3";</div>
      <div>
        package <span className="text-radix-mauve12">depot.core.v1</span>;
      </div>
      <div> </div>
      <div>
        service <span className="text-radix-mauve12">ProjectService</span> {'{'}
      </div>
      <div>
        {`  `}rpc <span className="text-radix-mauve12">ListProjects</span>(ListProjectsRequest) returns
        (ListProjectsResponse) {'{'}
      </div>
      <div>
        {`  `}rpc <span className="text-radix-mauve12">GetProject</span>(GetProjectRequest) returns (GetProjectResponse){' '}
        {'{'}
      </div>
      <div>
        {`  `}rpc <span className="text-radix-mauve12">CreateProject</span>(CreateProjectRequest) returns
        (CreateProjectResponse) {'{'}
      </div>
      <div>
        {`  `}rpc <span className="text-radix-mauve12">UpdateProject</span>(UpdateProjectRequest) returns
        (UpdateProjectResponse) {'{'}
      </div>
      <div>
        {`  `}rpc <span className="text-radix-mauve12">DeleteProject</span>(DeleteProjectRequest) returns
        (DeleteProjectResponse) {'{'}
      </div>
      <div>
        {`  `}rpc <span className="text-radix-mauve12">ListTrustPolicies</span>(ListTrustPoliciesRequest) returns
        (ListTrustPoliciesResponse) {'{'}
      </div>
      <div>
        {`  `}rpc <span className="text-radix-mauve12">AddTrustPolicy</span>(AddTrustPolicyRequest) returns
        (AddTrustPolicyResponse) {'{'}
      </div>
      <div>
        {`  `}rpc <span className="text-radix-mauve12">RemoveTrustPolicy</span>(RemoveTrustPolicyRequest) returns
        (RemoveTrustPolicyResponse) {'{'}
      </div>
      <div>{'}'}</div>
    </div>
  )
}
